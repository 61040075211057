<div class="content space-y-4">
    <div class="flex items-center space-x-2">
        <button class="bg-primary-500 rounded-full w-8 h-8" (click)="back()">
            <fa-icon [icon]="faChevronLeft" class="text-sm text-white"></fa-icon>
        </button>
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/hierarchy.svg" alt="" class="w-5">
            </div>
            <div class="">รายละเอียดเซ็ตสินค้า</div>
        </div>
    </div>
    @if(loading){
    <div class="card w-full">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }@else {
    <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2 h-full">
            <div class="card space-y-4 h-full">
                <div class="font-bold">ข้อมูลทั่วไป</div>
                <div class="flex justify-between pr-8">
                    <div class="">รหัสเซ็ต</div>
                    <div class="font-semibold">{{currentProductSet.set_sku}}</div>
                </div>
                <div class="flex justify-between">
                    <div class="">ชื่อเซ็ต</div>
                    <div class="font-semibold flex space-x-4">
                        <div>{{currentProductSet.name}}</div>
                        <button (click)="openNameDialog(currentProductSet.name)">
                            <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="flex justify-between pr-8">
                    <div class="">เปิดใช้งาน</div>
                    <div class="flex">
                        <app-toggle [id]="'detialtoggle-set-'+currentProductSet.set_sku"
                            [callbackToggle]="callbackActiveToggle"
                            [defaultValue]="currentProductSet.active"></app-toggle>
                    </div>
                </div>
                <div class="font-bold pt-4 border-t">รูปสินค้า</div>
                <div class=" flex justify-start space-x-2 w-full overflow-x-auto">
                    @for (product of currentProductSet.products; track $index) {
                    <div [matTooltip]="product.name">
                        <div class="flex items-center w-fit">
                            <img [src]="'https://storage.maxxgadget.com/img/product/' + product.sku + '/1.jpg'" alt=""
                                class="flex-none w-20 h-20 mr-2"
                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                        </div>
                        <div class="text-center">x{{product.qty}}</div>
                    </div>
                    }
                </div>
                <div class="border-t pt-4 w-full">
                    <div class="flex space-x-4 pb-4 justify-between">
                        <div class="font-bold">หมายเหตุ</div>
                        <button class="font-semibold" (click)="openNoteDialog(currentProductSet.note)">
                            <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                    </div>
                    <div class="pr-8">
                        <textarea [value]="currentProductSet?.note|| ''" disabled class="w-full text-area"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="card space-y-4 h-full">
            <div class="flex justify-between">
                <div class="font-bold">Check List</div>
                <div class="font-bold">{{getCheckedCheckLict()}}/{{checkList.length}}</div>
            </div>
            @if (checkList.length > 0) {
            <div class="space-y-4 h-96 overflow-y-auto">
                @for (item of checkList; track $index) {
                <div class="pr-4">
                    <div class="flex justify-between">
                        <div class="w-full flex space-x-2">
                            <input type="checkbox" [name]="item.channel" [id]="item.channel" [(ngModel)]="item.checked"
                                (change)="checkChannel(item.channel)" class="" />
                            <label [for]="item.channel" class=""
                                [ngClass]="{'line-through': item.checked}">{{item.name}}</label>
                        </div>
                        <div class="">
                            <button class="hover:underline hover:text-red-500"
                                (click)="removeCheckList(item.channel)">ลบ</button>
                        </div>
                    </div>
                    <div class="flex pl-7 space-x-2">
                        <div class="text-xs text-gray-500">{{getDateString(item.date)}}, {{getUserName(item.user_id)}}
                        </div>
                    </div>
                </div>
                }
            </div>
            }@else {
            <div class="h-96 flex justify-center items-center flex-col">
                <div class="h-32">
                    <img src="assets/images/web_20.png" alt="" class="h-full">
                </div>
                <div class="font-medium">ของานหน่อยค่ะ</div>
            </div>
            }
            <div class="flex space-x-2">
                <div class="w-full">
                    <mat-form-field class="w-full">
                        <mat-select class="" (selectionChange)="selectChannel($event)"
                            [value]='channelSelected' multiple>
                            @for (channel of channels; track $index) {
                            <mat-option class="s" [value]="channel.shop_ref">
                                {{channel.platform_name}}
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <div>
                        <fa-icon [icon]="faPlusCircle" class="text-primary-400 text-2xl"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card grid grid-cols-1 gap-4">
        <div class="w-full justify-between flex space-x-2 items-center">
            <div class="font-bold">
                เซ็ตสินค้าเดียวกัน
                <span class="text-sm font-normal text-lime-500 pl-2">( ) = ราคาปัจจุบันลดลง</span>
                <span class="text-sm font-normal text-red-500 pl-2">( ) = ราคาปัจจุบันเพิ่มขึ้น</span>
            </div>
            <button class="btn-sm btn-secondary flex items-center space-x-2" (click)="copyProduct()">
                <fa-icon class="text-sm" [icon]="faCopy"></fa-icon>
                <div>คัดลอกเซ็ตสินค้า</div>
            </button>
        </div>
        <div class="div-table">
            <table>
                <thead>
                    <tr class="thead-tr divide-x">
                        <th class="text-left">
                            SET
                        </th>
                        <th class="text-left">
                            Product
                        </th>
                        <th class="text-right">
                            ราคาขาย
                        </th>
                        <th class="text-right">
                            ราคาเซ็ต
                        </th>
                        <th class="text-right">
                            ราคา SRP/ชิ้น
                        </th>
                        <th class="text-right">
                            ราคา SRP
                        </th>
                        <th class="text-right">
                            ทุน(ช่อง 5)
                        </th>
                        <th class="text-right">
                            ทุน(+{{percent}}%)
                        </th>
                        <th class="text-right">
                            ทุนรวม
                        </th>
                        <!-- <th class="text-right">
                            เปิดใช้งาน
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    @for (row of productSet; track $index) {
                    <tr class="tbody-tr" [ngClass]="{'!bg-gray-50': !row.active}">
                        <td class="text-left border-r">
                            <div class="">
                                @if (row.price_change) {
                                <div class="flex space-x-1">
                                    <div class="hover:text-primary-500 hover:underline hover:cursor-pointer text-primary-500 font-bold"
                                        (click)="showPriceChangeDetail(row.set_sku)">{{row.set_sku}}</div>
                                    <span class="h-2 w-2 rounded-full opacity-75 label-danger"></span>
                                </div>
                                <!-- <div class="max-w-lg">{{row.name}}</div> -->
                                }@else {
                                <div class="hover:text-primary-500 hover:underline hover:cursor-pointer text-primary-500 font-bold"
                                    (click)="showPriceChangeDetail(row.set_sku)">{{row.set_sku}}</div>
                                <!-- <div class="max-w-lg">{{row.name}}</div> -->
                                }
                            </div>
                        </td>
                        <td class="text-left border-r">
                            <div class="w-[250px] lg:w-[300px] 2xl:w-full">
                                @for (product of row.products; track $index) {
                                <div class="whitespace-nowrap w-full truncate">
                                    x{{product.qty}} {{product.name}}
                                </div>
                                }
                            </div>
                        </td>
                        <td class="text-right border-r">
                            @for (product of row.products; track $index) {
                            <div class="font-semibold"
                                [ngClass]="{'text-red-500' : product.price < (product.current_cost_percent * product.qty)}">
                                {{product.price | number}}
                            </div>
                            }
                        </td>
                        <td class="text-right text-primary-500 font-semibold border-r">
                            {{row.total_price | number}}
                        </td>
                        <td class="text-right border-r">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{product.srp_price | number}}</div>
                            </div>
                            }
                        </td>
                        <td class="text-right border-r">
                            <div class="">
                                <div>{{row.total_srp | number}}</div>
                            </div>
                        </td>
                        <td class="text-right border-r">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{product.cost | number}}</div>
                                @if (product.cost !== product.current_cost) {
                                <div class=""
                                    [ngClass]="{'text-lime-500': (product.current_cost) < (product.cost), 'text-red-500': (product.current_cost) > (product.cost)}">
                                    ({{product.current_cost | number}})</div>
                                }
                            </div>
                            }
                        </td>
                        <td class="text-right border-r">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{product.cost_percent | number}}</div>
                                @if (product.cost_percent !== product.current_cost_percent) {
                                <div class=""
                                    [ngClass]="{'text-lime-500': (product.current_cost_percent) < (product.cost_percent), 'text-red-500': (product.current_cost_percent) > (product.cost_percent)}">
                                    ({{product.current_cost_percent | number}})</div>
                                }
                            </div>
                            }
                        </td>
                        <td class="text-right">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{(product.cost_percent * product.qty) | number}}</div>
                                @if ((product.cost_percent * product.qty) !== (product.current_cost_percent *
                                product.qty)) {
                                <div>
                                    <div class="" [ngClass]="{'text-lime-500': (product.current_cost_percent * product.qty) < (product.cost_percent *
                                    product.qty), 'text-red-500': (product.current_cost_percent * product.qty) > (product.cost_percent *
                                    product.qty)}">
                                        ({{(product.current_cost_percent * product.qty) | number}})
                                    </div>
                                </div>
                                }
                            </div>
                            }
                        </td>
                        <!-- <td class="">
                            <div class="flex justify-end gap-2">
                                <app-toggle [id]="'detialtoggle-set-'+row.set_sku"
                                    [callbackToggle]="callbackActiveToggle" [defaultValue]="row.active"></app-toggle>
                            </div>
                        </td> -->
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
    }
</div>