<div class="content space-y-4">

    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/price.svg" alt="" class="w-5">
            </div>
            <div class="">รายการสินค้า</div>
        </div>
    </div>


    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <input class="input" type="text" placeholder="กรอกชื่อ/รหัสสินค้า" formControlName="keyword">
            </div>
            <div class="">
                <label class="text-sm">หมวดหมู่</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="เลือกหมวดหมู่" formControlName="category_id">
                        <mat-option value="">ทั้งหมด</mat-option>
                        @for (item of categories; track $index) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="">
                <label class="text-sm">จำนวนในคลัง</label>
                <div class="flex gap-2 items-center">
                    <input class="input" type="number" placeholder="ต่ำสุด" formControlName="min_stock">
                    <div class="">-</div>
                    <input class="input" type="number" placeholder="สูงสุด" formControlName="max_stock">
                </div>
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>


    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && datasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && datasets.length > 0){
    <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr divide-x">
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-right">
                                จำนวนในคลัง
                            </th>
                            <!-- @for (item of channels; track $index) {
                            <th class="text-right">
                                {{item.platform_name}}
                            </th>
                            } -->
                            <th class="text-right">
                                ราคาออนไลน์
                            </th>
                            <th class="text-right">
                                ทุน(ช่อง 5)
                            </th>
                            <th class="text-center">
                                PriceList
                            </th>
                            <th class="text-center">
                                Stock Promotion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of datasets; track $index) {
                        <tr class="tbody-tr">
                            <td class="border-r">
                                <div class="flex items-center">
                                    <img [src]="'https://storage.maxxgadget.com/img/product/' + row.sku + '/1.jpg'"
                                        alt="" class="flex-none w-8 h-8 mr-2"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                    <div class="w-full flex flex-col truncate">
                                        <span class="w-full truncate whitespace-nowrap">{{ row.name }}</span>
                                        <div class="flex gap-2 items-center">
                                            <span class="text-xs pr-2 text-blue-500">{{row.sku}}</span>
                                            <span class="label label-primary">{{row.category.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-right border-r" [ngClass]="{'text-red-500':row.in_stock===0}">
                                {{row.in_stock | number}}
                            </td>
                            <!-- @for (item of channels; track $index) {
                            <td class="text-right border-r"
                                [ngClass]="{'text-red-500': getStock(item.shop_ref, row.sku) === 0 }">
                                {{getStock(item.shop_ref, row.sku) !== undefined ? (getStock(item.shop_ref, row.sku)|
                                number): '-'}}
                            </td>
                            } -->
                            <td class="text-right border-r">
                                {{(row?.['prices']?.['6'] || 0) | number}}
                            </td>
                            <td class="text-right border-r">
                                {{(row?.['prices']?.['5'] || 0) | number}}
                            </td>
                            <td class="text-center border-r">
                                @if(row.is_price_list){
                                <div class="whitespace-nowrap text-primary-500 !text-xs">
                                    <fa-icon [icon]="faCheck" class="mr-1"></fa-icon>
                                    Price List
                                </div>
                                }@else {
                                <button class="btn-sm btn-light whitespace-nowrap !text-xs"
                                    (click)="addProductPriceList(row.sku)">
                                    <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>
                                    Price List
                                </button>
                                }

                            </td>
                            <td class="text-center">
                                <button class="btn-sm btn-light whitespace-nowrap !text-xs"
                                    (click)="addStockPromotion(row)">
                                    <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>
                                    Add
                                </button>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }
</div>