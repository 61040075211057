<div class="content space-y-4">
    <div class="flex justify-between items-center">
        <div class="flex items-center space-x-2">
            <button class="bg-primary-500 rounded-full w-8 h-8" (click)="cancel()">
                <fa-icon [icon]="faChevronLeft" class="text-sm text-white"></fa-icon>
            </button>
            <div class="flex font-medium text-primary-400 gap-2">
                <div class="">
                    <img src="assets/svg/hierarchy.svg" alt="" class="w-5">
                </div>
                <div class="">สร้างเซ็ตสินค้า</div>
            </div>
        </div>
        <div class="flex space-x-2">
            <button class="btn-sm btn-light" type="button" (click)="cancel()">ยกเลิก</button>
            <button class="btn-sm btn-primary" type="button" [disabled]="form.invalid"
                (click)="submit()">บันทึกข้อมูล</button>
        </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="submit()" class="grid grid-cols-4 gap-4">
        <!-- Set Name Field -->
        <div class="col-span-full grid grid-cols-3 gap-4">
            <div class="card col-span-2 space-y-3">
                <div class="relative w-full">
                    <label for="name">* ชื่อเซ็ตสินค้า</label>
                    <input type="text" formControlName="name" id="name" class="input" placeholder="เช่น RABBIT COMBO"
                        autocomplete="off"
                        [ngClass]="{'input-invalid': form.get('name')?.invalid && form.get('name')?.touched}">
                    <div *ngIf="form.get('name')?.invalid && form.get('name')?.touched" class="error-message">
                        กรุณากรอกชื่อเซ็ตสินค้า
                    </div>
                </div>
                <div class="">
                    <label for="note">หมายเหตุ</label>
                    <textarea formControlName="note" id="note" class="w-full text-area" rows="3" placeholder="Note... 📝"></textarea>
                </div>
            </div>
            <div class="card col-span-1">
                <div class="space-y-2">
                    <label for="check-list">
                        <fa-icon [icon]="faListCheck" class="text-sm"></fa-icon> 
                        Check List
                    </label>
                    @for (item of channels; track $index) {
                    <div class="flex gap-2 text-sm">
                        <input type="checkbox" name="checkbox-channel-{{item.shop_ref}}"
                            id="checkbox-channel-{{item.shop_ref}}" [value]="item.shop_ref" class=""
                            (change)="checkListOnChange($event)" />
                        <label for="checkbox-channel-{{item.shop_ref}}" class="">{{item.platform_name}}</label>
                    </div>
                    }
                </div>
            </div>
        </div>

        <!-- Products Section -->
        <div formArrayName="products" class="card col-span-full space-y-10">
            <!-- Search Products -->
            <div class="flex space-x-6 items-center justify-start">
                <div>* สินค้าในเซ็ต</div>
                <div class="flex items-center space-x-2 relative">
                    <div>
                        <fa-icon [icon]="faPlusCircle" class="text-primary-400 text-2xl"></fa-icon>
                    </div>
                    <div class="w-96 flex items-center">
                        <div class="flex gap-2 w-full relative">
                            <input #product type="text" id="product" class="input" autocomplete="off"
                                placeholder="ค้นหาด้วย SKU, ชื่อสินค้า" [(ngModel)]="keyword"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="productSearch()">
                            <ul class="absolute z-10 w-96 !rounded-lg bg-white mat-elevation-z8 max-h-72 overflow-y-auto top-11 py-2"
                                *ngIf="showProductFilter">
                                <li *ngFor="let product of productsFilter"
                                    class="min-h-[48px] whitespace-nowrap hover:bg-secondary-100 hover:cursor-pointer flex items-center p-2 text-sm"
                                    (click)="selectProduct(product, 1,0)">
                                    <img [src]="'https://storage.maxxgadget.com/img/product/' + product.sku + '/1.jpg'"
                                        alt="" class="flex-none h-11 w-11 mr-2"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                    <div class="w-full flex flex-col truncate">
                                        <span class="pr-2 text-blue-500">{{ product.sku }} :</span>
                                        <span class="w-full truncate whitespace-nowrap">{{ product.name }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    @if (keyword.length > 0) {
                    <div class="absolute flex justify-center flex-col right-2">
                        <button (click)="clearSearch()">
                            <fa-icon [icon]="faXmark" class="text-secondary-900"></fa-icon>
                        </button>
                    </div>
                    }
                </div>
            </div>

            <!-- Product Table -->
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">สินค้า</th>
                            <th class="text-center w-24">จำนวนชิ้น</th>
                            <th class="text-right">ทุน(ช่อง5)</th>
                            <th class="text-right">ทุน+{{percent}}%</th>
                            <th class="text-right">ทุนรวม</th>
                            <th class="text-center w-32">ราคาขาย</th>
                            <th class="text-center w-32">ราคา SRP/ชิ้น</th>
                            <th class="text-right">จัดการ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- If no products selected -->
                        <tr *ngIf="products.controls.length === 0" class="tbody-tr">
                            <td colspan="100" class="text-center">
                                <div class="flex flex-col items-center py-6 space-y-3">
                                    <div class="h-10">
                                        <img src="assets/images/web_20.png" alt="" class="h-full">
                                    </div>
                                    <div class="font-medium">กรุณาเพิ่มสินค้า</div>
                                </div>
                            </td>
                        </tr>

                        <!-- Products Loop -->
                        <tr *ngFor="let product of products.controls; let i = index" [formGroupName]="i"
                            class="tbody-tr">
                            <td>
                                <div class="flex items-center gap-2">
                                    <img [src]="'https://storage.maxxgadget.com/img/product/' + product.get('sku')?.value + '/1.jpg'"
                                        alt="" class="w-10 h-10"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                    <div class="flex-wrap min-w-44">
                                        <div>{{ product.get('name')?.value }}</div>
                                        <div class="text-primary-500">{{ product.get('sku')?.value }}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-right">
                                <div class="w-20">
                                    <input type="number" formControlName="qty" class="input" autocomplete="off" min="1"
                                        [ngClass]="{'input-invalid': (product.get('qty')?.invalid && product.get('qty')?.touched)}">
                                    <div *ngIf="product.get('qty')?.invalid && product.get('qty')?.touched"
                                        class="error-message">
                                        จำนวนขั้นต่ำ 1
                                    </div>
                                </div>
                            </td>
                            <td class="text-right">{{ product.get('cost')?.value | number }}</td>
                            <td class="text-right">{{ product.get('cost_added')?.value | number }}</td>
                            <td class="text-right">{{ (product.get('cost_added')?.value * product.get('qty')?.value) |
                                number }}</td>
                            <td class="text-right">
                                <div class="w-32">
                                    <input type="number" formControlName="price" class="input" autocomplete="off"
                                        [ngClass]="{'input-invalid': ((product.get('cost_added')?.value * product.get('qty')?.value) > product.get('price')?.value) }">
                                    <div *ngIf=" ((product.get('cost_added')?.value * product.get('qty')?.value) > product.get('price')?.value) && !product.get('price')?.invalid && product.get('price')?.touched"
                                        class="error-message">
                                        ควรมากกว่าทุนรวม
                                    </div>
                                    <div *ngIf="product.get('price')?.invalid && product.get('price')?.touched"
                                        class="error-message">
                                        จำนวนขั้นต่ำ 0
                                    </div>
                                </div>
                            </td>
                            <td class="text-right">
                                <div class="w-32">
                                    <input type="number" formControlName="srp_price" class="input" autocomplete="off"
                                        [ngClass]="{'input-invalid': ((product.get('srp_price')?.value) <= (product.get('price')?.value / product.get('qty')?.value)) }">
                                    <div *ngIf=" ((product.get('srp_price')?.value) <= (product.get('price')?.value / product.get('qty')?.value)) && !product.get('srp_price')?.invalid && product.get('srp_price')?.touched"
                                        class="error-message">
                                        ควรมากกว่าราคาขาย/ชิ้น
                                    </div>
                                    <div *ngIf="product.get('srp_price')?.invalid && product.get('srp_price')?.touched"
                                        class="error-message">
                                        จำนวนขั้นต่ำ 0
                                    </div>
                                </div>
                            </td>
                            <td class="text-right">
                                <button class="btn-sm btn-danger" type="button" (click)="removeProduct(i)">ลบ</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Summary Section -->
            <div class="w-full grid grid-cols-4 gap-4 border-t border-dashed pt-6">
                <div>
                    <div>จำนวนสินค้า</div>
                    <input class="input" [value]="summaryItem()" disabled />
                </div>
                <div>
                    <div>ราคา SRP</div>
                    <input class="input" [value]="summarySRP()" disabled />
                </div>
                <div>
                    <div>ราคาเซ็ต</div>
                    <input class="input" [value]="summaryPrice()" disabled />
                </div>
                <div>
                    <div>ทุนรวมเซ็ต</div>
                    <input class="input" [value]="summaryCostAdded()" disabled />
                </div>
            </div>
        </div>
    </form>
</div>