<div class="content space-y-4">

    <div class="flex justify-between items-center">
        <div class="flex items-center gap-2">
            <button class="bg-primary-500 rounded-full w-8 h-8" (click)="back()">
                <fa-icon [icon]="faChevronLeft" class="text-sm text-white"></fa-icon>
            </button>
            <div class="flex font-medium text-primary-400 gap-2">
                <div class="">
                    <img src="assets/svg/tags.svg" alt="" class="w-5">
                </div>
                <div class="">Campaign Price Mapping</div>
            </div>
        </div>
    </div>

    <div class="card grid grid-cols-2 gap-6">

        <div class="col-span-1">
            <form [formGroup]="form" class="space-y-2" (change)="formOnchange()">
                <div class="">
                    <label class="text-sm">แพลตฟอร์ม</label>
                    <mat-form-field class="w-full">
                        <mat-select class="" placeholder="เลือกแพลตฟอร์ม" formControlName="platform">
                            <mat-option value="shopee">Shopee</mat-option>
                            <mat-option value="lazada">Lazada</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="">
                    <label class="text-sm">ช่องราคา</label>
                    <mat-form-field class="w-full">
                        <mat-select class="" placeholder="เลือกช่องราคา" formControlName="campaign_price_id">
                            @for (item of campaignData; track $index) {
                            <mat-option [value]="item.id">
                                <div class="flex gap-2">
                                    <div class="w-4 h-4" [ngStyle]="{'background-color': item.color}"></div>
                                    <div class="">{{item.group_name}} ({{item.name}})</div>
                                </div>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submitted && f['campaign_price_id'].errors && f['campaign_price_id'].errors['required']"
                        class="text-xs text-red-500">
                        กรุณาเลือก ช่องราคา</div>
                </div>
                @if(f['platform'].value === 'lazada'){
                <div class="w-full">
                    <label class="text-sm">คอลัมน์ SKU ร้านค้า (Code. D)</label>
                    <input class="input" type="text" placeholder="กรอกคอลัมน์ SKU ร้านค้า" formControlName="column_sku"
                        #column_sku (input)="column_sku.value = column_sku.value.toUpperCase()">
                    <div *ngIf="submitted && f['column_sku'].errors && f['column_sku'].errors['required']"
                        class="text-xs text-red-500">
                        กรุณากรอก คอลัมน์ SKU</div>
                </div>
                }
                @if(f['platform'].value === 'shopee'){
                <div class="w-full">
                    <label class="text-sm">คอลัมน์ รหัสสินค้า</label>
                    <input class="input" type="text" placeholder="กรอกคอลัมน์ รหัสสินค้า"
                        formControlName="column_platform_product_id" #column_platform_product_id
                        (input)="column_platform_product_id.value = column_platform_product_id.value.toUpperCase()">
                    <div *ngIf="submitted && f['column_platform_product_id'].errors && f['column_platform_product_id'].errors['required']"
                        class="text-xs text-red-500">
                        กรุณากรอก คอลัมน์ รหัสสินค้า</div>
                </div>
                <div class="w-full">
                    <label class="text-sm">คอลัมน์ รหัสตัวเลือกสินค้า</label>
                    <input class="input" type="text" placeholder="กรอกคอลัมน์ รหัสตัวเลือกสินค้า"
                        formControlName="column_platform_sku" #column_platform_sku
                        (input)="column_platform_sku.value = column_platform_sku.value.toUpperCase()">
                    <div *ngIf="submitted && f['column_platform_sku'].errors && f['column_platform_sku'].errors['required']"
                        class="text-xs text-red-500">
                        กรุณากรอก คอลัมน์ รหัสตัวเลือกสินค้า</div>
                </div>
                }
                <div class="w-full">
                    <label class="text-sm">คอลัมน์ ราคาแคมเปญ</label>
                    <input class="input" type="text" placeholder="กรอกคอลัมน์ ราคาแคมเปญ"
                        formControlName="column_result" #column_result
                        (input)="column_result.value = column_result.value.toUpperCase()">
                    <div *ngIf="submitted && f['column_result'].errors && f['column_result'].errors['required']"
                        class="text-xs text-red-500">
                        กรุณากรอก คอลัมน์ ราคาแคมเปญ</div>
                </div>
                <div class="flex justify-end pt-2 gap-2">
                    <button class="btn-sm btn-light w-fit self-end" (click)="resetForm()">รีเซ็ต</button>
                    <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ประมวลผล</button>
                </div>
            </form>
        </div>

        <div class="col-span-1 space-y-2">
            <div class="text-sm">แนบไฟล์</div>
            <div
                class="border border-dashed rounded-md h-32 content-center justify-items-center group hover:border-primary-400 duration-150 transition">
                <ng-container *ngIf="!excelData">
                    <button (click)="fileUpload.click()"
                        class="w-full h-full flex flex-col items-center justify-center">
                        <fa-icon [icon]="faArrowUpFromBracket"
                            class="text-2xl text-secondary-400 group-hover:text-primary-400 duration-150 transition"></fa-icon>
                        <div class="text-secondary-400 text-sm group-hover:text-primary-400 duration-150 transition">
                            Choose file to upload
                        </div>
                        <div class="text-secondary-400 text-sm group-hover:text-primary-400 duration-150 transition">
                            .xlsx, .xls
                        </div>
                    </button>
                </ng-container>
                <ng-container *ngIf="excelData">
                    <button (click)="fileUpload.click()"
                        class="w-full h-full flex flex-col items-center justify-center">
                        <div class="flex items-center space-x-2 text-gray-500">
                            <img src="assets/svg/xlsx.svg" alt="" class="w-10">
                            <div class="text-left">
                                <div class="">{{fileName}}</div>
                                <div class="text-sm">size : {{fileSize | number}} KB</div>
                            </div>
                        </div>
                    </button>
                </ng-container>
                <input type="file" (change)="onFileChange($event)" #fileUpload accept=".xlsx, .xls" hidden>
            </div>
            <div class="text-center">
                <fa-icon [icon]="faDownLong" class="text-2xl text-secondary-300"></fa-icon>
            </div>
            <div
                class="border border-dashed rounded-md h-32 w-full content-center justify-items-center hover:border-primary-400 duration-150 transition">
                @if(processing){
                <ng-container>
                    <button class="w-full h-full">
                        <div class="flex items-center justify-center space-x-2 animate-pulse">
                            <div class="w-10 h-10 bg-slate-200 rounded"></div>
                            <div class="space-y-2">
                                <div class="h-2 w-48 bg-slate-200 rounded"></div>
                                <div class="h-2 w-48 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </button>
                </ng-container>
                }
                @else if (converted && workbook) {
                <ng-container>
                    <button class="w-full h-full" (click)="exportFile()">
                        <div class="flex items-center justify-center space-x-2 text-gray-500">
                            <img src="assets/svg/xlsx.svg" alt="" class="w-10">
                            <div class="text-left">
                                <div class="">{{newFileName}}</div>
                                <div class="text-sm">size : {{newFileSize | number}} KB</div>
                            </div>
                        </div>
                    </button>
                </ng-container>
                }
                @else if (!processing && !converted) {
                <ng-container>
                    <button class="w-full h-full">
                        <div class="items-center space-x-2 text-secondary-300">
                            <fa-icon [icon]="faExclamationCircle" class="text-2xl text-secondary-300"></fa-icon>
                            <div class="">There is no usable file</div>
                        </div>
                    </button>
                </ng-container>
                }
            </div>
        </div>

    </div>

    <div class="card max-h-96 overflow-auto space-y-4" *ngIf="excelData">
        <div class="flex justify-between sticky left-0">
            <div class="text-sm">Preview Upload Data:</div>
            <div class="text-sm">พบข้อมูล {{excelData.length | number}} แถว</div>
        </div>
        <table>
            <thead>
                <tr>
                    <th class="text-xs bg-gray-100" *ngFor="let key of columns">{{ key.column }}</th>
                </tr>
                <tr>
                    <th class="text-xs bg-gray-100 max-w-40 truncate" *ngFor="let key of columns">{{ key.header }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of excelData.slice(0, 15)">
                    <td class="text-xs max-w-40 truncate" *ngFor="let key of columns">{{ row[key.header] }}</td>
                </tr>
                <tr *ngIf="excelData.length > 15">
                    <td colspan="100">... และอื่นๆ</td>
                </tr>
            </tbody>
        </table>
        <!-- <pre>{{ excelData | json }}</pre> -->
    </div>


</div>