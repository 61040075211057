<div class="content space-y-4">
    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/price.svg" alt="" class="w-5">
            </div>
            <div class="">รายการ Stock Promotion</div>
        </div>
    </div>

    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <input class="input" type="text" placeholder="กรอกชื่อ/รหัสสินค้า" formControlName="keyword">
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>

    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && datasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && datasets.length > 0){
    <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr divide-x">
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-left">
                                ช่องทาง
                            </th>
                            <th class="text-left">
                                ช่วงวันที่
                            </th>
                            <th class="text-center">
                                จำนวนสต๊อก
                            </th>
                            <th class="text-center">
                                จัดการ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of datasets; track $index) {
                        <tr class="tbody-tr">
                            <td class="border-r">
                                <div class="flex items-center">
                                    <img [src]="'https://storage.maxxgadget.com/img/product/' + row.sku + '/1.jpg'"
                                        alt="" class="flex-none w-8 h-8 mr-2"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                    <div class="w-full flex flex-col truncate">
                                        <span class="w-full truncate whitespace-nowrap">{{ row?.product_info?.name
                                            }}</span>
                                        <div class="flex gap-2 items-center">
                                            <span class="text-xs pr-2 text-blue-500">{{row.sku}}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-left border-r">
                                {{getChannelName(row.channel)}}
                            </td>
                            <td class="text-left border-r">
                                <div>
                                    {{formatDate(row.start_date)}} <span class="px-2 text-gray-500">ถึง</span>
                                    {{formatDate(row.end_date)}}
                                </div>
                                <div *ngIf="row.status === 'deleted'"
                                    class="w-fit px-2 py-1 text-xs rounded-lg border flex items-center space-x-2 bg-red-100 text-red-500 border-red-500">
                                    <fa-icon [icon]="faXmark" class="text-red-500"></fa-icon>
                                    <div>ยกเลิกแล้ว</div>
                                </div>
                                <div *ngIf="row.status !== 'deleted'"
                                    class="w-fit px-2 py-1 text-xs rounded-lg border flex items-center space-x-2"
                                    [ngClass]="{'bg-indigo-100 text-indigo-500 border-indigo-500': getStatusDisplay(row.start_date,row.end_date) === 'ใกล้จะมาถึง','bg-yellow-100 text-yellow-500 border-yellow-500': getStatusDisplay(row.start_date,row.end_date) === 'อยู่ในช่วง','bg-lime-100 text-lime-500 border-lime-500': getStatusDisplay(row.start_date,row.end_date) === 'สิ้นสุดแล้ว'}">
                                    <div *ngIf="getStatusDisplay(row.start_date,row.end_date) === 'ใกล้จะมาถึง'">
                                        <fa-icon [icon]="faHourglassStart" class="text-indigo-500"></fa-icon>
                                    </div>
                                    <div *ngIf="getStatusDisplay(row.start_date,row.end_date) === 'อยู่ในช่วง'">
                                        <fa-icon [icon]="faHourglassHalf" class="text-yellow-500"></fa-icon>
                                    </div>
                                    <div *ngIf="getStatusDisplay(row.start_date,row.end_date) === 'สิ้นสุดแล้ว'">
                                        <fa-icon [icon]="faCheckCircle" class="text-lime-500"></fa-icon>
                                    </div>
                                    <div>{{getStatusDisplay(row.start_date,row.end_date)}}</div>
                                </div>
                            </td>
                            <td class="text-center border-r text-blue-500 font-bold"
                                [ngClass]="{'line-through text-gray-500': row.status === 'deleted'}">
                                @if (row.status === 'wait' && (getStatusDisplay(row.start_date,row.end_date) ===
                                'ใกล้จะมาถึง' || getStatusDisplay(row.start_date,row.end_date) === 'อยู่ในช่วง')) {
                                <button *ngIf="!isEditing[row.id]" class="text-center font-semibold !w-16"
                                    [disabled]="!canAccess()" (click)="toggleEditStock(row.id)">
                                    {{ row.stock | number }}
                                </button>
                                <input *ngIf="isEditing[row.id]" type="number" id="edit-stock"
                                    class="input !w-16 text-right" autocomplete="off" [(ngModel)]="stock" [min]="0"
                                    [placeholder]="row.stock | number" (focusout)="toggleEditStock(row.id)"
                                    (keyup.enter)="updateStock(row.id)" />
                                }@else {
                                <div>
                                    {{ row.stock | number }}
                                </div>
                                }
                            </td>
                            <td>
                                <div class="flex justify-center">
                                    <button class="btn-danger btn-sm"
                                        *ngIf="getStatusDisplay(row.start_date,row.end_date) !== 'สิ้นสุดแล้ว' && row.status !== 'deleted'"
                                        (click)="delete(row)">ยกเลิก</button>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }
</div>