import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faHourglassStart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StockPromotionService } from '../../services/stock-promotion/stock-promotion.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-stock-promotion-list',
  templateUrl: './stock-promotion-list.component.html',
  styleUrl: './stock-promotion-list.component.css'
})
export class StockPromotionListComponent implements OnInit {


  faCheckCircle = faCheckCircle
  faHourglassStart = faHourglassStart
  faHourglassHalf = faHourglassHalf
  faXmark = faXmark


  loading = false
  datasets: any[] = []

  form: FormGroup;

  stockPromotions = [{
    "_id": {
      "$oid": "675bf4e4db3aa1ca2fc41c5d"
    },
    "id": "zMclYD",
    "sku": "D2200940",
    "start_date": "2024-12-12",
    "end_date": "2024-12-13",
    "stock": 500,
    "channel": "00000135",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079716713
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079716713
    }
  },
  {
    "_id": {
      "$oid": "675bf4eddb3aa1ca2fc41c60"
    },
    "id": "qugoR9",
    "sku": "D2200940",
    "start_date": "2024-12-12",
    "end_date": "2024-12-13",
    "stock": 500,
    "channel": "00000159",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079725357
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079725357
    }
  },
  {
    "_id": {
      "$oid": "675bf4f5db3aa1ca2fc41c63"
    },
    "id": "Bj3M60",
    "sku": "D2200940",
    "start_date": "2024-12-12",
    "end_date": "2024-12-13",
    "stock": 500,
    "channel": "00000167",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079733224
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079733224
    }
  },
  {
    "_id": {
      "$oid": "675bf501db3aa1ca2fc41c66"
    },
    "id": "1GOwcs",
    "sku": "D2200940",
    "start_date": "2024-12-12",
    "end_date": "2024-12-13",
    "stock": 500,
    "channel": "00000172",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079745832
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079745832
    }
  },
  {
    "_id": {
      "$oid": "675bf526db3aa1ca2fc41c69"
    },
    "id": "Kn0iM1",
    "sku": "D2100761",
    "start_date": "2024-12-12",
    "end_date": "2024-12-13",
    "stock": 500,
    "channel": "00000135",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079782273
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079782273
    }
  },
  {
    "_id": {
      "$oid": "675bf54fdb3aa1ca2fc41c6c"
    },
    "id": "YyRKsS",
    "sku": "D2100761",
    "start_date": "2024-12-12",
    "end_date": "2024-12-13",
    "stock": 500,
    "channel": "00000159",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079823595
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079823595
    }
  },
  {
    "_id": {
      "$oid": "675bf5d4db3aa1ca2fc41c75"
    },
    "id": "q5E824",
    "sku": "D2100761",
    "start_date": "2024-12-28",
    "end_date": "2024-12-30",
    "stock": 500,
    "channel": "00000167",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079956889
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079956889
    }
  },
  {
    "_id": {
      "$oid": "675bf5dbdb3aa1ca2fc41c78"
    },
    "id": "ziWTrX",
    "sku": "D2100761",
    "start_date": "2024-12-25",
    "end_date": "2024-12-30",
    "stock": 500,
    "channel": "00000172",
    "status": "completed",
    "created": {
      "by": "SYSTEM",
      "date": 1734079963540
    },
    "updated": {
      "by": "SYSTEM",
      "date": 1734079963540
    }
  }]

  channels: any[] = this.platformService?.channels || [];

  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  stock: any = 0;
  isEditing: any = [];

  constructor(
    private platformService: PlatformService,
    private util: UtilService,
    private fb: FormBuilder,
    private stockPromotionService: StockPromotionService,
    private modalService: ModalService,
    private authService: AuthService

  ) {
    this.form = this.fb.group({
      keyword: ['']
    });
  }

  ngOnInit(): void {
    this.getStockPromotionData()
  }

  getStockPromotionData() {
    this.loading = true;
    const filters: any = { offset: this.offset, limit: this.limit }
    const value = this.form.getRawValue();

    if (value.keyword) {
      filters['keyword'] = value.keyword
    }

    this.stockPromotionService.getAllStockPromotion(filters).then((res: any) => {
      if (res.success) {
        this.stockPromotions = res.data;
        this.countDataSource = res.count;
        this.setFormattingData();
      }
      this.loading = false;
    })
  }

  setFormattingData() {
    this.datasets = this.stockPromotions
    console.log(this.datasets)
  }


  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getStockPromotionData()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  getChannelName(id: string) {
    return this.channels.find(item => item.shop_ref === id)?.platform_name
  }

  formatDate(date: string) {
    return this.util.dayjs(date).format('DD/MM/YYYY')
  }

  getStatusDisplay(startDate: string, endDate: string) {
    const currentDate = this.util.dayjs().valueOf()
    const startDateTimestamp = this.util.dayjs(startDate).startOf('day').valueOf();
    const endDateTimeStamp = this.util.dayjs(endDate).endOf('day').valueOf()
    if (currentDate >= startDateTimestamp && currentDate <= endDateTimeStamp) {
      return 'อยู่ในช่วง'
    } else if (currentDate < startDateTimestamp) {
      return 'ใกล้จะมาถึง'
    } else {
      return 'สิ้นสุดแล้ว'
    }
  }

  submitForm() {
    this.offset = 0;
    this.pageIndex = 0;
    this.getStockPromotionData();
  }

  delete(data: any) {
    console.log(data)
    this.modalService.openModal('confirm-warning', 'ยกเลิกสต๊อกโปรโมชัน', `${data.sku} ${this.getChannelName(data.channel)} ${this.formatDate(data.start_date)} ถึง ${this.formatDate(data.end_date)} ระบบจะอัพเดตสต๊อกตามจำนวนคลังจริง`).then((confirm: any) => {
      console.log(confirm)
      if (confirm) {
        this.stockPromotionService.cancelStockProduct(data.id).then((response: any) => {
          if (response.success) {
            console.log('deleted')
            this.modalService.openModal('success', 'ลบข้อมูลสำเร็จ', '')
            setTimeout(() => {
              this.modalService.closeAll()
              this.getStockPromotionData()
            }, 500);
          } else {
            this.modalService.openModal('fail', 'อัพเดตไม่สำเร็จ', response.message)
          }
        })

      }
    })
  }

  toggleEditStock(id: string) {
    this.stock = null;
    this.isEditing[`${id}`] = this.isEditing[`${id}`] ? !this.isEditing[`${id}`] : true;

    if (this.isEditing[`${id}`]) {
      setTimeout(() => {
        document.getElementById('edit-stock')?.focus()
      }, 10)
    }
  }

  updateStock(id: string) {
    setTimeout(() => {
      document.getElementById('edit-stock')?.blur()
    }, 10)

    if (!this.canAccess()) {
      this.modalService.openModal('info', `ขออภัย`, 'สิทธิ์ของคุณไม่สามารถแก้ไขได้')
      return;
    }

    if (this.stock >= 0) {
      const body = {
        stock: this.stock
      }
      console.log(body)
      this.stockPromotionService.updateStockProduct(id, body).then((response: any) => {
        console.log(response)
        if (response.success) {
          const index = this.datasets.findIndex((item: any) => item.id === id)
          if (index > -1) {
            this.datasets[index].stock = response?.data?.stock || 0
          }
        }
      })
    }
  }

  canAccess() {
    return this.authService.user?.role ? ['admin', 'marketing'].includes(this.authService.user.role) : false;
  }

}
