import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockPromotionService {
  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllStockPromotion(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/stock-promotion`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  cancelStockProduct(id: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/stock-promotion/deleted/${id}`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateStockProduct(id: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/stock-promotion/${id}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
