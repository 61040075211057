import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductSetApiService } from '../../services/api/product-set-api.service';

export interface UpdateStockSetModalModel {
  sku: string;
  name: string;
  stock: number;
  products: Array<{ sku: string }>;
  channels: Array<{ channel: string, name: string, platform: string, stock: number, updated_date: number }>
}

@Component({
  selector: 'app-update-stock-set-modal',
  templateUrl: './update-stock-set-modal.component.html',
  styleUrl: './update-stock-set-modal.component.css'
})
export class UpdateStockSetModalComponent implements OnInit {

  productDetials: UpdateStockSetModalModel = {
    sku: '',
    name: '',
    stock: 0,
    products: [],
    channels: []
  }

  updateData = false
  updating = false
  updateSuccess = false
  updateStockResult = undefined
  updateResult: any = {}

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UpdateStockSetModalModel,
    private ref: MatDialogRef<UpdateStockSetModalComponent>,
    private productSetApi: ProductSetApiService
  ) { }

  ngOnInit(): void {
    this.updateData = false
    this.productDetials = this.data
    console.log(this.productDetials)
  }

  close() {
    this.ref.close({ confirm: false });
  }

  async onConfirmUpdate() {
    this.updateData = true
    this.updating = true
    this.updateSuccess = false
    this.updateResult = {}
    this.updateStockResult = undefined

    const syncStockProductFormWarehouseResult: any = await this.syncStockProductFormWarehouse(this.productDetials.products.map(item => item.sku))
    if (!syncStockProductFormWarehouseResult.success) {
      // falied
      return
    } else {
      if (this.productDetials.sku.startsWith('P')) {
        const syncStockProductSetFormWarehouseResult: any = await this.syncStockProductSetFormWarehouse(this.productDetials.sku)
        this.updateStockResult = syncStockProductSetFormWarehouseResult?.success || false
        if (!syncStockProductSetFormWarehouseResult.success) {
          // falied
          return
        }
      } else {
        this.updateStockResult = syncStockProductFormWarehouseResult?.success || false
      }
    }

    for (const channel of this.productDetials.channels) {
      if (channel.platform === 'LAZADA') {
        const result: any = await this.syncStockToLazada(channel.channel, this.productDetials.sku)
        this.updateResult[channel.channel] = result?.success || false
      }
      if (channel.platform === 'SHOPEE') {
        const result: any = await this.syncStockToShopee(channel.channel, this.productDetials.sku)
        this.updateResult[channel.channel] = result?.success || false
      }
    }
    this.updating = false
    this.updateSuccess = true;

  }


  delay(ms: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, ms)
    })
  }

  async syncStockProductFormWarehouse(sku: string[]) {
    const result = await this.productSetApi.syncStockProductBySku({ skus: sku })
    await this.delay(3000)
    return result
  }

  async syncStockProductSetFormWarehouse(setSku: string) {
    const result = await this.productSetApi.syncStockProductSetBySku({ set_sku: [setSku] })
    await this.delay(1000)
    return result
  }

  async syncStockToLazada(channel: string, sku: string) {
    const body = {
      "sku": [sku],
      "no_check_stock_change": true
    }
    console.log(body)
    const result = await this.productSetApi.syncStockLazada(channel, body)
    await this.delay(1000)
    return result
  }

  async syncStockToShopee(channel: string, sku: string) {
    const body = {
      "sku": [sku],
      "no_check_stock_change": true
    }
    console.log(body)
    const result = await this.productSetApi.syncStockShopee(channel, body)
    await this.delay(1000)
    return result
  }

}
