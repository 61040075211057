<div class="relative bg-white w-fit h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="text-center">เพิ่ม Stock Promotion</div>
    <div class="absolute top-0 right-4">
        <button class="font-normal" (click)="close()">
            <fa-icon [icon]="faXmark" class=""></fa-icon>
        </button>
    </div>
    @if (state === '') {
    <form class="w-screen max-w-lg h-96 overflow-y-auto" [formGroup]="form">
        <div class="py-2 flex justify-center items-center">
            <!-- <div class="w-24"></div> -->
            <div class="flex justify-center w-full">
                <div class="flex justify-start items-start">
                    <img [src]="'https://storage.maxxgadget.com/img/product/' + data.sku + '/1.jpg'" alt=""
                        class="flex-none w-28 h-28 mr-2"
                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                </div>
            </div>
        </div>
        <div class="py-2 flex items-center">
            <div class="w-24">สินค้า</div>
            <div class="flex w-full">
                <!-- <div class="flex justify-start items-start">
                        <img [src]="'https://storage.maxxgadget.com/img/product/' + data.sku + '/1.jpg'" alt=""
                            class="flex-none w-16 h-16 mr-2"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div> -->
                <div class="w-full flex flex-col truncate">
                    <span class="w-full truncate whitespace-nowrap">{{ data.name }}</span>
                    <div class="flex gap-2 items-center">
                        <span class="pr-2 text-blue-500">{{data.sku}}</span>
                        <!-- <span class="label label-primary">{{data.category.name}}</span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="py-2 flex items-center">
            <div class="w-24">จำนวน</div>
            <input class="input !text-left" type="number" formControlName="stock" placeholder="0">
        </div>
        <div class="py-2 flex items-center">
            <div class="w-24">วันที่</div>
            <mat-form-field class="w-full relative">
                <button class="input !text-left" (click)="picker.open()">
                    <div class="text-nowrap gap-2">
                        <span class="whitespace-nowrap mr-2">{{onDateSelected(selectedStartDate)}} <span
                                class="px-4">ถึง</span>
                            {{onDateSelected(selectedEndDate)}}</span>
                    </div>
                    <div class="absolute top-2 right-2">
                        <fa-icon [icon]="faCalendar" class="text-gray-400 text-end"></fa-icon>
                    </div>
                </button>
                <mat-date-range-input class="!hidden" id="datepicker" [rangePicker]="picker" [max]="maxDate">
                    <input matStartDate [(ngModel)]="selectedStartDate" [max]="maxDate"
                        [ngModelOptions]="{standalone: true}">
                    <input matEndDate [(ngModel)]="selectedEndDate" [max]="maxDate"
                        [ngModelOptions]="{standalone: true}" (dateChange)="onDateRangeSelected()">
                </mat-date-range-input>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="py-2 flex items-center">
            <div class="w-24">ช่องทาง</div>
            <div class="w-full space-y-4">
                <mat-form-field class="w-full">
                    <mat-select class="!pl-2.5" (selectionChange)="formCreateString($event.value)"
                        [value]='toggleAll? 0: channelSelected' multiple>
                        <mat-option class="s" #allSelected (click)="toggleAllSelection()"
                            [value]="0">ทั้งหมด</mat-option>
                        @for (channel of channels; track $index) {
                        <mat-option class="s" [disabled]="allSelected.selected" [value]="channel.shop_ref">
                            {{channel.platform_name}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                @if(this.channelSelected?.length>0){
                <div class="w-full flex flex-row gap-1 flex-wrap">
                    @for (item of displaychannel(); track $index) {
                    <div class="label label-secondary w-fit !text-xs text-nowrap">
                        {{item.platform_name}}
                        <span class="cursor-pointer ml-3" (click)="removeChannelSelected(item)">&#x2715;</span>
                    </div>
                    }
                    @if(this.channelSelected?.length>1){
                    <button class="label label-light w-fit !text-xs text-nowrap border" type="button"
                        (click)="removeChannelSelectedAll()">ลบทั้งหมด</button>
                    }
                </div>
                }
            </div>
        </div>
    </form>
    }

    @if (state === 'saving') {
    <div class="w-screen max-w-lg h-96 overflow-y-auto space-y-2">
        @for (channel of loadChannel; track $index) {
        <div class="flex justify-between">
            <div>
                <div>{{channel.name}}</div>
            </div>
            <div>
                @if (channel.status === 'success') {
                <div class="text-lime-500">
                    <div>สำเร็จ</div>
                    <div>{{channel.error}}</div>
                </div>
                }
                @if (channel.status === 'failed') {
                <div class="text-red-500">
                    <span>ไม่สำเร็จ, </span>
                    <span>{{channel.error}}</span>
                </div>
                }
                @if (channel.status === 'wait') {
                <div>กำลังบันทึก</div>
                }
            </div>
        </div>
        }
    </div>
    }

    @if (state === '') {
    <div class="flex space-x-2">
        <button type="button" (click)="cancel()" class="btn-sm btn-light w-36">ยกเลิก</button>
        <button type="button" (click)="confirm()" class="btn-sm btn-primary w-36"
            [disabled]="form.invalid">บันทึกข้อมูล</button>
    </div>
    }

    @if (state === 'saving') {
    <div class="flex space-x-2">
        <button type="button" (click)="back()" class="btn-sm btn-light w-36">ย้อนกลับ</button>
        <button type="button" (click)="cancel()" class="btn-sm btn-primary w-36"
            [disabled]="form.invalid">เสร็จสิ้น</button>
    </div>
    }

</div>