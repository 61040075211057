import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faCalendar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { ProductApiService } from '../../services/api/product-api.service';

@Component({
  selector: 'app-add-stock-promotion-dialog',
  templateUrl: './add-stock-promotion-dialog.component.html',
  styleUrl: './add-stock-promotion-dialog.component.css'
})
export class AddStockPromotionDialogComponent {

  faXmark = faXmark
  faCalendar = faCalendar

  selectedStartDate: any;
  selectedEndDate: any;
  maxDate = this.utilService.dayjs().add(365, 'days').format();
  form: FormGroup;

  channelSelected: any;
  toggleAll: boolean = true;

  channels: any[] = this.platformService?.channels || [];

  state = ''
  loadChannel: any[] = []

  constructor(
    private ref: MatDialogRef<AddStockPromotionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilService: UtilService,
    private fb: FormBuilder,
    private platformService: PlatformService,
    private productApiService: ProductApiService,
  ) {
    console.log(this.data)
    this.selectedStartDate = this.utilService.dayjs().format()
    this.selectedEndDate = this.utilService.dayjs().format()
    const date = `${this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')},${this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')}`
    this.form = this.fb.group({
      date: [date, Validators.required],
      channel: [[]],
      stock: ['', Validators.required],
      sku: this.data.sku
    });
  }

  close() {
    this.ref.close({ confirm: false });
  }

  onDateRangeSelected() {
    if (this.selectedEndDate && this.selectedStartDate) {
      const date = `${this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')},${this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')}`
      this.form.get('date')?.setValue(date)
      this.form.get('date')?.updateValueAndValidity();
    }
  }

  onDateSelected(date: any) {
    return this.utilService.dayjs(date).format('DD-MM-YYYY')
  }

  formCreateString(value: any) {
    this.channelSelected = value;
    this.form.get('channel')?.setValue(this.channelSelected)
  }

  toggleAllSelection() {
    this.toggleAll = !this.toggleAll
    if (this.toggleAll) {
      this.channelSelected = []
    } else {
      delete this.channelSelected
    }
    this.form.get('channel')?.setValue(this.channelSelected || [])
  }

  removeChannelSelected(channel: any) {
    this.channelSelected = this.channelSelected.filter((e: string) => e !== channel.shop_ref)
    if (this.channelSelected.length === 0) {
      this.toggleAllSelection()
    } else {
      this.form.get('channel')?.setValue(this.channelSelected || [])
    }
  }

  removeChannelSelectedAll() {
    this.toggleAllSelection()
  }

  displaychannel() {
    return this.channels.filter(c => this.channelSelected.includes(c.shop_ref))
  }

  async confirm() {
    console.log(this.form.getRawValue())
    console.log(this.form.invalid)
    if (this.form.invalid) return
    const values = this.form.getRawValue()
    this.state = 'saving';
    this.loadChannel = []
    const channels = values.channel.length > 0 ? values.channel : this.channels.map(item => item.shop_ref)
    console.log(channels)
    channels.forEach((channel: any) => {
      this.loadChannel.push({
        channel: channel,
        name: this.channels.find(item => item.shop_ref === channel)?.platform_name,
        status: 'wait',
        error: ''
      })
    });
    for (const channel of this.loadChannel) {
      const body = {
        "sku": values.sku,
        "start_date": values.date.split(',')[0],
        "end_date": values.date.split(',')[1],
        "channel": channel.channel,
        "stock": values.stock
      }
      console.log(body)
      const result: any = await this.productApiService.createStockPromotion(body)
      if (result.success) {
        channel.status = 'success';
      } else {
        channel.status = 'failed';
        channel.error = result.message === 'conflict date' ? 'ช่วงเวลาทับซ้อน' : result.message
      }
    }
    console.log(this.loadChannel)
  }

  cancel() {
    this.ref.close()
  }

  back() {
    this.state = ''
    this.loadChannel = []
  }
}
