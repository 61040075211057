import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchProductSet(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateProductSet(set_sku: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-set/update/${set_sku}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createProductSet(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/product-set/create`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProductSet(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateMarkPriceChange(set_sku: string, mark_price_change: boolean) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-set/mark-price-change/${set_sku}/${mark_price_change}`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getSameProductSet(setSku: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set/same/${setSku}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchPriceList(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/price-list/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createPriceList(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/price-list/create`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updateProductSetCheckList(set_sku: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-set/check-list/update/${set_sku}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  addNewCheckListProductSet(set_sku: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-set/check-list/add/${set_sku}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  removeCheckListProductSet(set_sku: string, channel: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-set/check-list/remove/${set_sku}/${channel}`, {}, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProductPlatform(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-platform/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }


  syncStockProductBySku(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/product/sync-stock-by-sku`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  syncStockProductSetBySku(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/product-set/sync-stock-by-sku`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  syncStockLazada(channel: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-platform/sync/stock/lazada/${channel}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  syncStockShopee(channel: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product-platform/sync/stock/Shopee/${channel}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createStockPromotion(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/stock-promotion`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
